<header style="height: 80px;">
  <!--SMARTPHONE-->
  <div class=" navbar navbar-default navbar-fixed-top hidden-sm hidden-md hidden-lg bg-light p-0">
    <div class="container p-0" >
      <div class="container">
      <div class="navbar-header" style="margin-bottom: 0px;">

        <button type="button" class="navbar-toggle" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
          <span class="sr-only">Toggle navigation</span>
          <span class="fa fa-bars" style="font-size: 14px;"></span>
        </button>

          <a  class="navbar-brand navbar-brand-mobile" style="cursor: pointer;" data-bs-dismiss="offcanvas" (click)="goToHome()">
            <img height="100%" alt="Brand" src="./assets/icons/New_LH_Logo_Navbar_Desktop.png" class="center-block" id="logo" >
          </a>
      </div>
      </div>


      <div class="offcanvas offcanvas-top" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel" style="height: 100%;">
        <div class="container offcanvas-header d-flex">

          <a  class="navbar-brand navbar-brand-mobile" id="offcanvasWithBothOptionsLabel" style="cursor: pointer;" data-bs-dismiss="offcanvas" (click)="goToAboutUs()">
            <img height="100%" alt="Brand" src="./assets/icons/New_LH_Logo_Navbar_Desktop.png" class="center-block" id="logo">
          </a>

          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" style="float: right; margin-top: 30px; margin-right: 1%;"></button>

        </div>

        <div class="container offcanvas-body p-0">
          <div class="container dropdown col-sm-12 p-0">
            <div id="accordion">
              <div class="card">
                <div class="dropbtn-mobile card-header btn text-start" data-bs-toggle="collapse" (click)="goToHome()" data-bs-dismiss="offcanvas">
                  <a  style="width: 100%; text-align: start;">
                    <span class="droptext-mobile" data-bs-dismiss="offcanvas" (click)="goToHome()">Home</span>                
                  </a>
                </div>
              </div>
                
              <div class="card">
                <div class="dropbtn-mobile card-header btn text-start" data-bs-toggle="collapse" href="#collapseOne">
                  <a class="vissually-hidden"  style="width: 100%; text-align: start;">
                    <span class="droptext-mobile pt-2 pb-2">Our Story</span>
                  </a>
                </div>
                <div id="collapseOne" class="collapse" data-bs-parent="#accordion">
                  <div class="card-body">
                    <ul class="mobile-droplist p-0">
                      <button class="dropItem-mobile btn" (click)="goToAboutUs()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToAboutUs()">About Us</span>
                      </button>
                      <button class="dropItem-mobile btn" (click)="goToMilestone()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToMilestone()">Milestone</span>
                      </button>
                      <!-- <button class="dropItem-mobile btn" (click)="goToCertificate()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToCertificate()">Certificate</span>
                      </button>
                      <button class="dropItem-mobile btn" (click)="goToOurStrength()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToOurEvents()">Our Event</span>
                      </button> -->
                    </ul>
                  </div>
                </div>
              </div>
        
              <div class="card">
                <div class="dropbtn-mobile card-header btn text-start" data-bs-toggle="collapse" href="#collapseTwo">
                  <a class=""  style="width: 100%; text-align: start;">
                    <span class="droptext-mobile pt-2 pb-2">Services</span>
                  </a>
                </div>
                <div id="collapseTwo" class="collapse" data-bs-parent="#accordion">
                  <div class="card-body">
                    <ul class="mobile-droplist p-0">
                      <button class="dropItem-mobile btn" (click)=" goToPalletDelivery()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)=" goToPalletDelivery()">LTL Pallet Delivery</span>
                      </button>
                      <button class="dropItem-mobile btn" (click)="goToCrossBorder()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToCrossBorder()">Cross Border</span>
                      </button>
                      <button class="dropItem-mobile btn" (click)="goToCharteredTransport()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToCharteredTransport()">Chartered Transport</span>
                      </button>
                      <button class="dropItem-mobile btn" (click)="goToWarehouse()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToWarehouse()">Warehouse</span>
                      </button>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="dropbtn-mobile card-header btn text-start" data-bs-toggle="collapse" (click)="goToPromotion()" data-bs-dismiss="offcanvas">
                  <a  style="width: 100%; text-align: start;">
                    <span class="droptext-mobile" data-bs-dismiss="offcanvas" (click)="goToPromotion()">Promotion</span>                
                  </a>
                </div>
              </div>
        
              <div class="card">
                <div class="dropbtn-mobile card-header btn text-start" data-bs-toggle="collapse" href="#collapseThree">
                  <a class=""  style="width: 100%; text-align: start;" >
                    <span class="droptext-mobile pt-2 pb-2">Join Us</span>
                  </a>
                </div>
                <div id="collapseThree" class="collapse" data-bs-parent="#accordion">
                  <div class="card-body">
                    <ul class="mobile-droplist p-0">
                      <button class="dropItem-mobile btn" (click)="goToWhyUs()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToWhyUs()">Why Us</span>
                      </button>
                      <button class="dropItem-mobile btn" (click)="goToPersonal()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToPersonal()">Create Account</span>
                      </button>
                      <button class="dropItem-mobile btn" (click)="goToCareer()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToCareer()">Career</span>
                      </button>
                    </ul>
                  </div>
                </div>
              </div>
        
              <!-- <div class="card">
                <div class="dropbtn-mobile card-header btn text-start" data-bs-toggle="collapse" href="#collapseFour">
                  <a class=""  style="width: 100%; text-align: start;">
                    <span class="droptext-mobile pt-2 pb-2">Book Now</span>
                  </a>
                </div>
                <div id="collapseFour" class="collapse" data-bs-parent="#accordion">
                  <div class="card-body">
                    <ul class="mobile-droplist p-0">
                      <button class="dropItem-mobile btn" (click)=" goToSchedules()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)=" goToSchedules()">Schedules</span>
                      </button>
                      <button class="dropItem-mobile btn" (click)="goToNewBooking()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToNewBooking()">New Booking</span>
                      </button> -->
                      <!-- <button class="dropItem-mobile btn" (click)="goToNewBooking()" data-bs-dismiss="offcanvas">
                        <span class=" pt-2 pb-2" data-bs-dismiss="offcanvas" (click)="goToNewBooking()">Duplicate Booking</span>
                      </button> -->
                    <!-- </ul>
                  </div>
                </div>
              </div> -->
        
              <div class="card">
                <div class="dropbtn-mobile card-header btn text-start" data-bs-toggle="collapse" (click)="goToNetwork()" data-bs-dismiss="offcanvas">
                  <a  style="width: 100%; text-align: start;">
                    <span class="droptext-mobile" data-bs-dismiss="offcanvas" (click)="goToNetwork()">Network</span>                
                  </a>
                </div>
              </div>
        
              <div class="card">
                <div class="dropbtn-mobile card-header btn text-start" data-bs-toggle="collapse" (click)="goToContact()" data-bs-dismiss="offcanvas">
                  <a  style="width: 100%; text-align: start;">
                    <span class="droptext-mobile" data-bs-dismiss="offcanvas" (click)="goToContact()">Contact Us</span>                
                  </a>
                </div>
              </div>

              <!-- <div class="card">
                <div class="dropbtn-mobile card-header btn text-start" data-bs-toggle="collapse" (click)="goToFaq()" data-bs-dismiss="offcanvas">
                  <a  style="width: 100%; text-align: start;">
                    <span class="droptext-mobile" data-bs-dismiss="offcanvas" (click)="goToFaq()">FAQ</span>                
                  </a>
                </div>
              </div> -->

            </div>
      
            <div class="loginButton-mobile col-sm-12" data-bs-dismiss="offcanvas">
              <span type="button" data-bs-dismiss="offcanvas"  class="btn btn-lg"><a href="https://pallet.linehaul.com.my" style="color: white; display: flex; justify-content: center;">Try Our Service</a></span>
            </div>

        </div>
      </div>
    </div>
    </div>
  </div>


    <!--LAPTOP-->
  <nav class="headerbox navbar-default navbar-expand-md navbar-expand-lg navbar-light bg-light navbar-fixed-top d-sm-none d-md-block hidden-xs">
    <div class="container p-0">
        <a class="navbar-brand d-flex mt-2 w-auto m-0" style="cursor: pointer;" (click)="goToHome()">
        <img class="brandLogo" src="./assets/icons/New_LH_Logo_Navbar_Desktop.png" alt="linehaulLogoFooter" width="100%" height="100%">
        </a>
        <ul class="nav navbar-nav me-auto d-flex" style="margin-left: 1%; min-width: 400px;">
          <li class="nav-item dropdown ">
            <button class="dropbtn"><span class="effect" (click)="goToHome()">Home</span></button>
          </li>

          <li class="nav-item dropdown ">
            <button class="dropbtn" data-bs-toggle="dropdown"><span class="effect">Our Story</span></button>
            <ul class="dropdown-menu ">
              <li><a class="dropdown-item pt-4 pb-4 dropdown-topItem" (click)="goToAboutUs()    ">About Us</a></li>
              <li><a class="dropdown-item pt-4 pb-4 dropdown-bottomItem " (click)="goToMilestone()">Milestone</a></li>
              <!-- <li><a class="dropdown-item pt-4 pb-4" (click)="goToCertificate()">Certificate</a></li>
              <li><a class="dropdown-bottomItem dropdown-item pt-4 pb-4" (click)="  goToOurEvents()">Our Event</a></li> -->
            </ul>
          </li>

          <li class="nav-item dropdown  ">
            <button class="dropbtn" data-bs-toggle="dropdown"><span class="effect">Services</span></button>
            <ul class="dropdown-menu ">
              <li><a class="dropdown-item pt-4 pb-4" (click)=" goToPalletDelivery()">LTL Pallet Delivery</a></li>
              <li><a class="dropdown-item pt-4 pb-4" (click)="goToCrossBorder()">Cross Border</a></li>
              <li><a class="dropdown-item pt-4 pb-4 dropdown-topItem" (click)="goToCharteredTransport()">Chartered Transport</a></li>
              <li><a class="dropdown-item pt-4 pb-4 dropdown-bottomItem" (click)="goToWarehouse()">Warehouse</a></li>
            </ul>
          </li>

          <li class="nav-item dropdown  ">
            <button class="dropbtn"><span class="effect" (click)="goToPromotion()">Promotion</span></button>
          </li>

          <li class="nav-item dropdown ">
            <button class="dropbtn" data-bs-toggle="dropdown"><span class="effect">Join Us</span></button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item pt-4 pb-4 dropdown-topItem" (click)="goToWhyUs()">Why Us</a></li>
              <li><a class="dropdown-item pt-4 pb-4" (click)="goToPersonal()">Create Account</a></li>
              <li><a class="dropdown-item pt-4 pb-4 dropdown-bottomItem " (click)="goToCareer()">Career</a></li>
            </ul>
          </li>

          <!-- <li class="nav-item dropdown  ">
            <button class="dropbtn" data-bs-toggle="dropdown"><span class="effect">Book Now</span></button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item pt-4 pb-4 dropdown-topItem" (click)=" goToSchedules()">Schedules</a></li>
              <li><a class="dropdown-item pt-4 pb-4" (click)="goToNewBooking()">New Booking</a></li>
            </ul>
          </li> -->

          <li class="nav-item dropdown  ">
            <button class="dropbtn"><span class="effect" (click)="goToNetwork()">Network</span></button>
          </li>

          <li class="nav-item dropdown ">
            <button class="dropbtn"><span class="effect" (click)="goToContact()">Contact Us</span></button>
          </li> 

          <!-- <li class="nav-item dropdown ">
            <button class="dropbtn"><span class="effect" (click)="goToFaq()">FAQ</span></button>
          </li> -->
        </ul>
        <span type="button" class="d-flex loginButton w-auto btn btn-lg"  style="float: right;"><a href="https://pallet.linehaul.com.my" style="color: white;">Try Our Service</a></span>
    </div>
  </nav>
</header>